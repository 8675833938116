<template>
  <div class="profile scroll-container" v-if="userData">
    <CategoryTitle :category="category" />

    <div class="d-block text-uppercase font-weight-bold secondary--text mb-2">
      {{ $t("profile.header.personalData") }}
    </div>
    <v-form ref="profileform" v-model="isProfileFormValid">
      <v-row class="my-3">
        <v-col cols="12" sm="6">
          <!-- nome -->
          <v-text-field
            color="secondary"
            v-model="userData.person.firstName"
            :label="$t('profile.firstName')"
            :rules="[requiredRules()]"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <!-- cognome -->
          <v-text-field
            color="secondary"
            v-model="userData.person.lastName"
            :label="$t('profile.lastName')"
            :rules="[requiredRules()]"
            required
            outlined
            dense
          />
        </v-col>

        <v-col cols="12" sm="6">
          <!-- telefono -->
          <v-text-field
            color="secondary"
            v-model="userData.contact.homePhone"
            :label="$t('profile.homePhone')"
            :rules="isHomePhoneRules"
            outlined
            dense
          />
        </v-col>

        <v-col cols="12" sm="6">
          <!-- cellulare -->
          <v-text-field
            color="secondary"
            v-model="userData.phone"
            :label="$t('profile.phone')"
            :rules="isGenericPhoneRules"
            required
            outlined
            dense
            ><v-tooltip slot="append" top max-width="300" open-on-click>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">$info</v-icon>
              </template>
              <span>{{ $t("registration.phoneTooltip") }}</span>
            </v-tooltip></v-text-field
          >
        </v-col>

        <v-col cols="12" sm="6">
          <!-- indirizzo -->
          <v-text-field
            color="secondary"
            v-model="userData.billingAddress.address1"
            :label="$t('profile.addresses.address')"
            :rules="[requiredValue()]"
            required
            outlined
            dense
          />
        </v-col>

        <v-col cols="12" sm="3">
          <!-- numero civico -->
          <v-text-field
            color="secondary"
            v-model="userData.billingAddress.addressNumber"
            :label="$t('profile.addresses.number')"
            :rules="[requiredValue()]"
            required
            outlined
            dense
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="userData.billingAddress.postalcode"
            :label="$t('profile.addresses.postalCode')"
            name="postal code"
            outlined
            dense
            :rules="[requiredRules()]"
            required
          />
        </v-col>

        <v-col cols="12" sm="6">
          <!-- città -->
          <v-text-field
            color="secondary"
            v-model="userData.billingAddress.city"
            :label="$t('profile.addresses.city')"
            :rules="[requiredValue()]"
            required
            outlined
            dense
          />
        </v-col>

        <v-col cols="12" sm="6">
          <!-- provincia -->
          <v-text-field
            color="secondary"
            v-model="userData.billingAddress.province"
            :label="$t('profile.addresses.province')"
            :rules="[requiredValue()]"
            required
            outlined
            dense
          />
        </v-col>

        <!-- Ha codice fiscale? -->
        <v-col cols="12">
          <v-switch
            inset
            :label="$t('profile.hasFiscalCode')"
            class="italian-citizen mb-2"
            color="secondary"
            v-model="noFiscalCode"
          />
        </v-col>

        <v-col cols="12" sm="6" v-if="!noFiscalCode">
          <!-- codice fiscale -->
          <v-text-field
            ref="fiscalCode"
            v-model="userData.person.fiscalCode"
            :label="$t('profile.fiscalCode')"
            :rules="isCfRules"
            outlined
            dense
            required
            :readonly="readonlyFiscalCode"
          >
            <v-tooltip slot="append" top max-width="300" open-on-click>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">$info</v-icon>
              </template>
              <span>{{ $t("registration.fiscalCodeTooltip") }}</span>
            </v-tooltip>
          </v-text-field>
        </v-col>

        <v-col cols="12" sm="6" v-if="noFiscalCode">
          <!-- data di nascita -->
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="birthDate"
                :label="$t('profile.birthDate')"
                :rules="ageRules"
                required
                readonly
                outlined
                dense
              >
                <v-icon v-bind="attrs" v-on="on" slot="append"
                  >$editcalendar</v-icon
                >
              </v-text-field>
            </template>
            <v-date-picker
              locale="it-IT"
              ref="picker"
              v-model="userData.person.birthDate"
              min="1910-01-01"
              first-day-of-week="1"
              type="date"
              no-title
              @input="menu = false"
            />
          </v-menu>
        </v-col>

        <v-col
          v-if="noFiscalCode"
          cols="12"
          sm="6"
          class="d-flex flex-column flex-sm-row align-start"
        >
          <!-- genere -->
          <v-radio-group
            dense
            v-model="userData.person.gender"
            mandatory
            :column="!$vuetify.breakpoint.xs"
            :label="$t('profile.gender')"
            class="gender pt-2"
            row
            :rules="[requiredRules()]"
          >
            <v-radio value="M" color="secondary"
              ><template v-slot:label>
                <span>M</span>
              </template>
            </v-radio>
            <v-radio value="F" color="secondary"
              ><template v-slot:label>
                <span>F</span>
              </template>
            </v-radio></v-radio-group
          >
        </v-col>

        <v-col cols="12" sm="6" v-if="noFiscalCode">
          <!-- Stato di nascita -->
          <v-combobox
            :label="$t('profile.birthCountry')"
            v-model="bornCountry"
            :items="countries"
            required
            height="40"
            :rules="requiredCountry"
            outlined
            dense
            return-object
            append-icon=""
            item-text="name"
            item-value="name"
            @change="selectBornCountry"
          />
        </v-col>

        <v-col cols="12" sm="6" v-if="noFiscalCode">
          <!-- luogo di nascita -->
          <v-text-field
            :placeholder="$t('profile.birthPlace')"
            v-model="userData.person.birthPlace"
            required
            :rules="[requiredRules()]"
            outlined
            dense
          />
        </v-col>

        <v-col cols="12" sm="6">
          <!-- pec -->
          <v-text-field
            v-model="userData.person.emailCertified"
            :label="$t('profile.pec')"
            :rules="isPecRules"
            outlined
            dense
          />
        </v-col>

        <v-col cols="12" sm="6">
          <!-- vat sdi -->
          <v-text-field
            color="secondary"
            v-model="userData.person.vatSdiCode"
            :label="$t('profile.vatSdiCode')"
            :rules="isSDICodeRules"
            outlined
            dense
          />
        </v-col>
      </v-row>

      <div class="d-flex justify-center justify-md-end mt-3 mt-sm-6">
        <v-btn
          color="secondary"
          large
          elevation="0"
          min-width="250"
          :block="$vuetify.breakpoint.xs"
          :disabled="!isProfileFormValid"
          @click="updateProfile()"
          :loading="loadingUserChange"
        >
          {{ $t("profile.updatePersonalData") }}
        </v-btn>
      </div>
    </v-form>
    <v-divider class="my-6" />

    <div class="d-block text-uppercase font-weight-bold secondary--text mb-2">
      {{ $t("profile.header.email") }}
    </div>
    <v-form ref="emailform" v-model="isEmailFormValid">
      <v-row class="my-3">
        <v-col cols="12" sm="6">
          <!-- email -->
          <v-text-field
            color="secondary"
            v-model="this.userData.email"
            :label="$t('profile.email.email')"
            readonly
            required
            outlined
            dense
          />
        </v-col>
        <v-col cols="12" sm="6">
          <!-- email -->
          <v-text-field
            v-model="newEmail"
            :rules="emailRules"
            :label="`${$t('profile.email.newEmail')}`"
            required
            outlined
            dense
          />
        </v-col>
        <!-- conferma email -->
        <!-- <v-col cols="12" sm="6">
          <v-text-field
            color="secondary"
            :rules="emailConfirmRules"
            :label="`${$t('profile.email.emailConfirm')}`"
            required
            outlined
            dense
          ></v-text-field>
        </v-col> -->
      </v-row>
      <div class="d-flex justify-center justify-md-end mt-3 mt-sm-6">
        <v-btn
          color="secondary"
          large
          elevation="0"
          min-width="250"
          :block="$vuetify.breakpoint.xs"
          :disabled="!isEmailFormValid"
          @click="updateEmail()"
          :loading="loadingEmailChange"
        >
          {{ $t("profile.updateEmailData") }}
        </v-btn>
      </div>
    </v-form>

    <v-divider class="my-6" />

    <div class="d-block text-uppercase font-weight-bold secondary--text mb-2">
      {{ $t("profile.header.password") }}
    </div>
    <v-form ref="passwordform" v-model="isPasswordFormValid">
      <v-row class="my-3">
        <v-col cols="12" sm="6">
          <!-- password -->
          <v-text-field
            color="secondary"
            v-model="oldPassword"
            :label="`${$t('profile.oldPassword')}`"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordRules"
            @click:append="toggleShowPassword"
            required
            outlined
            dense
          />
        </v-col>
        <v-col cols="0" sm="6"> </v-col>
        <v-col cols="12" sm="6">
          <!-- password -->
          <v-text-field
            color="secondary"
            v-model="password"
            :label="`${$t('profile.password')}`"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordRules"
            @click:append="toggleShowPassword"
            required
            outlined
            dense
          />
        </v-col>
        <v-col cols="12" sm="6">
          <!-- conferma password -->
          <v-text-field
            color="secondary"
            :label="`${$t('profile.passwordConfirm')}`"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordConfirmRules"
            @click:append="toggleShowPassword"
            required
            outlined
            dense
          />
        </v-col>
      </v-row>
      <div class="d-flex justify-center justify-md-end mt-3 mt-sm-6">
        <v-btn
          color="secondary"
          large
          elevation="0"
          min-width="250"
          :block="$vuetify.breakpoint.xs"
          :disabled="!isPasswordFormValid"
          @click="updatePassword()"
          :loading="loadingPasswordChange"
        >
          {{ $t("profile.updatePassword") }}
        </v-btn>
      </div>
    </v-form>

    <v-divider class="my-6" />

    <div class="d-block text-uppercase font-weight-bold secondary--text mb-2">
      {{ $t("profile.header.editDisclaimer") }}
    </div>
    <v-form
      ref="disclaimerform"
      v-model="isDisclaimerFormValid"
      lazy-validation
    >
      <RegistrationModuleDisclaimer
        v-if="registrationModules"
        :disclaimerList="registrationModules"
        :options="disclaimerOptions"
        @disclaimerChanged="disclaimerChanged"
      />
      <div class="d-flex justify-center justify-md-end mt-3 mt-sm-6">
        <v-btn
          color="secondary"
          large
          elevation="0"
          min-width="250"
          :block="$vuetify.breakpoint.xs"
          :disabled="!isDisclaimerFormValid"
          @click="updateDisclaimers()"
          :loading="loadingDisclaimerChange"
        >
          {{ $t("profile.updateDiscalimer") }}
        </v-btn>
      </div>
    </v-form>

    <v-divider class="my-6" />

    <div class="d-block text-uppercase font-weight-bold secondary--text mb-2">
      {{ $t("profile.header.anonymizeAccount") }}
    </div>
    <span
      >Tramite questa funzione il tuo account verrà eliminato e non potrai più
      fare ordini<br />
      L'opzione è disponibile solo se non hai ordini da ritirare
    </span>
    <ResponseMessage class="mt-3" :response="responseAnonymazeAccount" />
    <div no-gutters class="d-flex justify-center justify-md-end mt-3 mt-sm-6">
      <v-btn
        large
        color="secondary"
        depressed
        min-width="250"
        class="text-uppercase mb-2"
        @click="anonymizeAccount()"
        :loading="loadingAnonymizeAccount"
        >{{ $t("profile.anonymizeAccountBtn") }}
      </v-btn>
    </div>

    <RecaptchaDisclaimer @click="clicked" />

    <div v-if="isCordova && isBetaTester" class="mt-5">
      <a :href="developmentLink">Apri versione di test </a>
    </div>
    <v-dialog v-model="removeDialog" max-width="400">
      <div class="vuedl-layout__closeBtn" @click="removeDialog = false">
        ×
      </div>
      <v-card>
        <v-card-title class="anonymize-dialog-title">
          {{ $t("profile.anonymizeProfileDialog.title") }}
        </v-card-title>
        <v-card-text
          class="anonymize-dialog-text body-1 pt-8"
          style="font-size: 14px !important"
          v-html="$t('profile.anonymizeProfileDialog.text')"
        >
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click.prevent="anonymizeAccount(true)" @mousedown.stop>
            Si
          </v-btn>
          <v-btn class="secondary" text @click="removeDialog = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style lang="scss">
.profile {
  div[class*="col-"] {
    padding: 0 12px !important;
  }
  a {
    color: var(--v-default-base);
  }
  .v-autocomplete {
    box-shadow: none;
    -webkit-box-shadow: none;
  }
  .update-module-discaimer-btn {
    min-width: 250px !important;
  }
  .recaptcha-disclaimer {
    margin-top: 30px;
    a {
      color: var(--v-anchor-base);
    }
  }
}
.anonymize-dialog-title {
  padding: 12px !important;
}
.gender,
.italian-citizen {
  margin-top: 0;
}
</style>
<script>
import {
  requiredValue,
  isEmail,
  isNumber,
  isGenericPhone,
  isHomePhone,
  minLength,
  minCapitalCharacter,
  minSymbolCharacter,
  isSDICode
} from "~/validator/validationRules";
import { isAdultAge, isValidCf, isValidCountryItem } from "@/tosanoValidators";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import RegistrationModuleDisclaimer from "@/commons/components/disclaimer/RegistrationModuleDisclaimer.vue";
import ResponseMessage from "@/components/ResponseMessage.vue";
import RecaptchaDisclaimer from "@/commons/components/RecaptchaDisclaimer.vue";

import clickHandler from "~/mixins/clickHandler";
import categoryMixins from "~/mixins/category";

import TosanoCustomService from "@/service/TosanoCustomService";
import UserService from "~/service/userService";

import CodiceFiscale from "codice-fiscale-js";
import { mask } from "vue-the-mask";
import find from "lodash/find";
import forEach from "lodash/forEach";
import get from "lodash/get";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Profile",
  components: {
    CategoryTitle,
    RegistrationModuleDisclaimer,
    ResponseMessage,
    RecaptchaDisclaimer
  },
  mixins: [clickHandler, categoryMixins],
  directives: { mask },
  data() {
    return {
      menu: false,
      disclaimerList: null,
      userDisclaimer: null,
      registrationModules: null,
      disclaimerToChange: null,
      responseAnonymazeAccount: {},
      removeDialog: false,
      isProfileFormValid: false,
      isEmailFormValid: false,
      isPasswordFormValid: false,
      isDisclaimerFormValid: false,
      showPassword: false,
      oldPassword: null,
      password: null,
      newEmail: null,
      birthdateMenu: false,
      loadingUserChange: false,
      loadingEmailChange: false,
      loadingPasswordChange: false,
      loadingDisclaimerChange: false,
      loadingAnonymizeAccount: false,
      noFiscalCode: false,
      readonlyFiscalCode: false,
      requiredRules: requiredValue,
      requiredValue: requiredValue,
      requiredCountry: [
        requiredValue("Selezionare una nazione"),
        isValidCountryItem()
      ],
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      dateRules: [requiredValue("Digitare la data di nascita")],
      isGenericPhoneRules: [
        requiredValue("Digitare il numero di telefono"),
        isGenericPhone()
      ],
      isHomePhoneRules: [isHomePhone()],
      isSDICodeRules: [isSDICode()],
      isPecRules: [isEmail()],
      isCfRules: [requiredValue("Digitare il codice fiscale"), isValidCf()],
      ageRules: [
        requiredValue("Inserire la data di nascita"),
        isAdultAge("L'utilizzo del sito è riservato ai maggiorenni")
      ],
      isEmail: isEmail,
      isNumber: isNumber,
      fidelityCardRules: [
        requiredValue(),
        isNumber("Deve contenere solo cifre")
      ],
      bornCountry: { itemId: null, name: null },
      countries: [],
      // emailConfirmRules: [
      //   v => !!v || "Confermare l'e-mail",
      //   v => v === this.newEmail || "Le e-mail non coincidono"
      // ],
      isPhoneNumberRules: [
        requiredValue("Numero di telefono"),
        isGenericPhone()
      ],
      passwordRules: [
        requiredValue("Digitare la password"),
        minLength(8, "La password deve essere lunga almeno 8 caratteri"),
        minCapitalCharacter(),
        minSymbolCharacter()
      ],
      passwordConfirmRules: [
        v => !!v || "Confermare la password",
        v => v === this.password || "Le password non coincidono"
      ],
      userData: {
        contact: {},
        person: {},
        billingAddress: {}
      },
      cities: [],
      response: {},
      disclaimerOptions: {
        color: "secondary"
      }
    };
  },
  computed: {
    ...mapGetters({
      isBetaTester: "cart/isBetaTester"
    }),
    birthDate: {
      get: function() {
        if (
          this.userData.person.birthDate &&
          this.$dayjs(this.userData.person.birthDate).isValid()
        ) {
          return this.$dayjs(this.userData.person.birthDate).format(
            "DD/MM/YYYY"
          );
        } else {
          return null;
        }
      },
      set: function(value) {
        if (value) {
          this.userData.person.birthDate = this.$dayjs(
            value,
            "DD/MM/YYYY"
          ).format("YYYY-MM-DD");
        } else {
          this.userData.person.birthDate = null;
        }
      }
    },
    developmentLink() {
      //da testare se device esiste veramente in vue.js
      // eslint-disable-next-line no-undef
      let platform = device ? device.platform.toLowerCase() : "ios";
      return "/mobile/develop/" + platform + "/index.html";
    }
  },
  methods: {
    ...mapActions({
      doLogout: "cart/doLogout",
      loadCart: "cart/loadCart"
    }),
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    async loadCountriesList() {
      let allCountries = await TosanoCustomService.getCountries(-1);
      this.countries = allCountries;
    },
    selectBornCountry(item) {
      this.bornCountry = item;
    },
    getBirthdayFromFiscalCode() {
      var cf = new CodiceFiscale(this.userData.person.fiscalCode);
      if (cf.birthday) {
        this.userData.person.birthDate = this.$dayjs(cf.birthday).format(
          "YYYY-MM-DD"
        );
      }
    },
    async getUserData() {
      const result = await UserService.getUserDetail();

      if (result) {
        console.log(result);
        this.userData = result;
        this.noFiscalCode = this.userData.person.fiscalCode.length < 1;
        this.readonlyFiscalCode = this.checkReadonlyFiscalCode();
      }
      console.log("user: ", this.userData);
    },
    checkReadonlyFiscalCode() {
      return (
        this.userData.person.fiscalCode.length > 0 &&
        CodiceFiscale.check(this.userData.person.fiscalCode.toUpperCase())
      );
    },
    async updateProfile() {
      let _this = this;
      _this.userData.person.personInfos = [
        // has no fiscal code
        {
          personInfoTypeId: 11,
          value: _this.noFiscalCode
        },
        // bornCountry mapping
        {
          personInfoTypeId: 14,
          value: !_this.noFiscalCode ? 1 : _this.bornCountry.itemId
        }
      ];

      if (
        !_this.noFiscalCode &&
        _this.userData.person.fiscalCode &&
        !_this.userData.person.birthday
      ) {
        _this.userData.person.birthday = _this.getBirthdayFromFiscalCode();
      }
      _this.userData.firstName = _this.userData.person.firstName;
      _this.userData.lastName = _this.userData.person.lastName;
      try {
        _this.response = {};
        _this.loadingUserChange = true;
        if (_this.$refs.profileform.validate()) {
          // _this.userData.defaultStoreAddress = {
          //   addressId: _this.userData.defaultStoreAddressId
          // };
          let params = _this.userData;
          let res = await TosanoCustomService.updateUserDetail(params);
          _this.loadingUserChange = false;
          if (res) {
            if (res.response?.errors?.length) {
              _this.response = res.response.errors[0];
            } else {
              _this.userData = res.data.user;
              _this.noFiscalCode = _this.userData.person.fiscalCode.length < 1;
              _this.readonlyFiscalCode = _this.checkReadonlyFiscalCode();
              await _this.loadCart();
            }
          }
        }
      } catch (response) {
        _this.response = response;
      } finally {
        _this.loadingUserChange = false;
      }
    },
    async updatePassword() {
      let _this = this;
      try {
        _this.response = {};
        _this.loadingPasswordChange = true;
        if (_this.$refs.passwordform.validate()) {
          let res = await UserService.updatePassword(
            _this.oldPassword,
            _this.password
          );
          _this.loadingPasswordChange = false;
          if (res) {
            _this.loadCart();
          }
        }
      } catch (response) {
        _this.response = response;
      } finally {
        _this.loadingPasswordChange = false;
      }
    },
    async anonymizeAccount(perfom = false) {
      this.removeDialog = true;
      if (perfom) {
        this.loadingAnonymizeAccount = true;
        let result = await UserService.anonymizeAccount();
        if (result.response.status === 0) {
          this.removeDialog = false;
          // se utente davvero anonimizzato -> logout
          await this.doLogout();
          this.loadingAnonymizeAccount = false;
          this.$router.push({ name: "Home" });
        } else {
          this.responseAnonymazeAccount = result.response;
          this.removeDialog = false;
          this.loadingAnonymizeAccount = false;
        }
      }
    },
    async updateEmail() {
      let _this = this;
      try {
        _this.response = {};
        _this.loadingEmailChange = true;
        if (_this.$refs.emailform.validate()) {
          let res = await UserService.updateEmailRequest(this.newEmail);
          _this.loadingEmailChange = false;

          if (res) {
            _this.loadCart();
          }
        }
      } catch (response) {
        _this.response = response;
      } finally {
        _this.loadingEmailChange = false;
      }
    },
    setUserDisclaimerValue(disclaimerId, value) {
      let disclaimer = this.userDisclaimer.registrationModuleDisclaimers.find(
        item =>
          item?.registrationDisclaimer?.registrationDisclaimerId ===
          disclaimerId
      );
      if (disclaimer) {
        disclaimer.value = value;
      }
    },
    async updateDisclaimers() {
      let vm = this;
      try {
        console.log("updateDisclaimers", vm.disclaimerToChange);
        vm.loadingDisclaimersChange = true;
        if (vm.$refs.disclaimerform.validate()) {
          if (vm.disclaimerToChange && vm.disclaimerToChange !== null) {
            let registrationModulesParam = vm.disclaimerToChange.map(
              disclaimer => {
                let obj = {
                  registrationDisclaimer: {
                    registrationDisclaimerId:
                      disclaimer.registrationDisclaimerId
                  },
                  value: disclaimer.value
                };
                if (disclaimer.registrationModuleDisclaimerId) {
                  obj.registrationModuleDisclaimerId =
                    disclaimer.registrationModuleDisclaimerId;
                }
                return obj;
              }
            );

            if (vm.userDisclaimer && vm.userDisclaimer.registrationModules) {
              vm.userDisclaimer.registrationModules[0].registrationModuleDisclaimers = registrationModulesParam;
            } else {
              vm.userDisclaimer = {
                registrationModules: [
                  {
                    registrationModuleAction: {
                      registrationModuleActionId: 1,
                      name: "Inserimento"
                    },
                    registrationModuleType: {
                      registrationModuleTypeId: 2,
                      name: "REG_WEB",
                      description: ""
                    },
                    addDate: new Date().toISOString(),
                    registrationModuleDisclaimers: registrationModulesParam
                  }
                ]
              };
            }
          }
          console.log("vm.userDisclaimer to send", vm.userDisclaimer);
          let result = await UserService.updateUserDisclaimers(
            vm.userDisclaimer.registrationModules[0]
          );
          console.log("this.userDisclaimer before", this.userDisclaimer);
          if (
            result &&
            result.registrationModules &&
            result.registrationModules.length > 0
          ) {
            this.userDisclaimer = result;
          }
          console.log("this.userDisclaimer after", this.userDisclaimer);

          vm.loadingDisclaimersChange = false;
        }
      } catch (e) {
        console.log(e);
      } finally {
        vm.loadingDisclaimersChange = false;
      }
    },
    disclaimerChanged(userDisclaimer) {
      console.log("disclaimerChanged", userDisclaimer);
      this.disclaimerToChange = userDisclaimer;
    },
    async loadDisclaimer() {
      let storeId = 5;
      let registrationModuleTypeId = 2;
      await this.getDisclaimerList(registrationModuleTypeId, storeId);
      await this.getUserDisclaimer(registrationModuleTypeId, storeId);
    },
    async getDisclaimerList() {
      let vm = this;
      let result = await UserService.getRegistrationDisclaimerList(2, 5);
      if (result && result.registrationDisclaimers) {
        vm.disclaimerList = result.registrationDisclaimers;
      }
    },
    async getUserDisclaimer(registrationModuleTypeId, storeId) {
      let result = await UserService.getUserDisclaimerListByType(
        registrationModuleTypeId,
        storeId
      );
      if (
        result &&
        result.registrationModules &&
        result.registrationModules.length > 0 &&
        result.registrationModules[0].registrationModuleDisclaimers
      ) {
        this.userDisclaimer = result;
        result.registrationModules[0].registrationModuleDisclaimers.forEach(
          disclaimer => {
            let disclaimerFromList = this.disclaimerList.find(
              item =>
                item.registrationDisclaimerId ===
                disclaimer.registrationDisclaimer.registrationDisclaimerId
            );
            if (disclaimerFromList) {
              disclaimerFromList.value = disclaimer.value;
              disclaimerFromList.registrationModuleDisclaimerId =
                disclaimer.registrationModuleDisclaimerId;
            }
          }
        );
      }
      console.log("this.userDisclaimer", this.userDisclaimer);
      this.registrationModules = this.disclaimerList;
    },
    onAppTrackingTransparency() {
      window.plugins.impacTracking.trackingAvailable(
        function(available) {
          if (!available) {
            console.log(
              "Tracciamento non disponibile o disabilitato dall'utente"
            );
            window.plugins.impacTracking.canRequestTracking(
              function(result) {
                // Show results in the console
                console.log(result);
                if (result == true) {
                  window.plugins.impacTracking.requestTracking(
                    undefined,
                    function(res) {
                      console.log(res);
                      this.disabledTrackingTransparency = false;
                    },
                    function(err) {
                      console.log(err);
                      //TODO: tracking non abilitato
                      this.disabledTrackingTransparency = true;
                    }
                  );
                }
              },
              function(err) {
                console.log(err);
                //TODO: c'è stato un errore: tracking non abilitato
                this.disabledTrackingTransparency = true;
              }
            );
          } else {
            window.plugins.impacTracking.requestTracking(
              undefined,
              function(res) {
                console.log(res);
                this.disabledTrackingTransparency = false;
              },
              function(err) {
                console.log(err);
                this.disabledTrackingTransparency = true;
              }
            );
          }
        },
        function(err) {
          console.log(err);
          this.disabledTrackingTransparency = true;
        }
      );
    }
  },
  async mounted() {
    let newEmailToken = get(this, "$route.query.emailchanged", null);
    if (newEmailToken) {
      await UserService.updateEmail(newEmailToken);
    }
    this.loadCountriesList();
    await this.getUserData();
    await this.loadDisclaimer();
    if (this.$route.params.navToPreferences) {
      this.$vuetify.goTo(this.$refs.disclaimerform);
    }
  },
  watch: {
    // workaround per il il check di validità dei campi e
    // per mostrare i messaggi di errore di validazione
    // che vengono rimossi dal v-if del noFiscalCode
    noFiscalCode(isNoFiscalCode) {
      this.$nextTick(() => {
        this.$refs.profileform.validate();
        // con questo si fixa il popolamento del campo bornCountry
        // cercando la country di riferimento in countries[] tramite il
        // valore della personInfo 11
        if (isNoFiscalCode && this.userData.person?.personInfos.length) {
          forEach(this.userData.person.personInfos, i => {
            if (i.personInfoTypeId.toString() === "14" && i.value) {
              let countryItem = find(this.countries, c => {
                return c.itemId.toString() === i.value.toString();
              });
              if (countryItem) {
                this.selectBornCountry(countryItem);
              }
            }
          });
        } else {
          this.bornCountry = null;
        }
      });
    }
  }
};
</script>
